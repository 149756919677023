@import url("https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap");

.top-nav {
  position: sticky;
  top: 0px;
  margin-bottom: 0px;
  z-index: 5;
  width: 100%;
  background-color: rgb(32 32 32 / 93%);
  height: 60px;
  backdrop-filter: blur(10px);
  transition: all ease-in-out 0.5s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.div-game-logo {
  height: 45px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  bottom: 0px;
}

.div-game-logo p {
  font-family: "Fredericka the Great", cursive;
  background: #151515;
  padding: 10px;
  border-radius: 5px;
}

.div-menu {
  width: 70px;
  justify-content: center;
  height: 55px;
  align-items: center;
  font-size: 25px;
  /* position: absolute; */
  top: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.div-search-content {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 20px;
}

.div-search-icon {
  font-size: 25px;
  color: rgb(117 117 117);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 10px;
}

.div-press-key-search {
  font-size: 14px;
  color: rgb(117 117 117);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  background: #2d2d2db0;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

.input-disabled-search {
  background: transparent;
  border: 1px solid;
  height: 35px;
  font-size: 17px;
  padding-left: 40px;
  border-radius: 5px;
  width: 170px;
  cursor: pointer;
  border-color: rgb(117 117 117);
}


.input-disabled-search:focus,
.input-disabled-search:active {
  border-color: rgb(117 117 117);
}

.pro-div-img {
  width: 30px;
  /* position: absolute; */
  right: 20px;
  border-radius: 100%;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0px;
}

.pro-nav-img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

/* right content */
.div-right-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 20px;
}

/* left content */
.div-left {
  display: flex;
  align-items: center;
}

.div-parent-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 18px !important;
  margin-left: 20px;
}

.div-change-bg-hover {
  background: transparent;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
}

.div-change-bg-hover:hover {
  background: #282828;
}

.div-platform {
  background: #252525 !important;
  color: white !important;
}

.platform-parent-dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.platform-parent-dropdown span {
  display: flex;
}

.platform-dropdown {
  position: absolute;
  background: #1f1f1f;
  width: 200px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px #161616;
}

.div-platform-with-icons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.div-platform-name {
  padding: 15px;
  cursor: pointer;
}

.div-platform-name:hover {
  background-color: #2b2a2a;
}

.div-sub-platform {
  position: absolute;
  left: 100%;
  top: 0px !important;
}

a {
  color: white;
  text-decoration: none;
}

.sub-platform-with-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.sub-platform-with-arrow span {
  display: flex;
  align-items: center;
}

.div-search-parent {
  position: sticky;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 90px;
  z-index: 10;
}

.div-in-search-icon {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: #727272;
  right: 0px;
  width: 50px;
  border-radius: 5px;
  background: #323232;
  cursor: pointer;
}

.in-search-games {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: #232323;
  border: 1px solid transparent;
  padding-left: 15px;
  font-size: 16px;
  color: white;
}

.in-search-games:focus {
  outline: none;
  border-color: #333333;
}

.div-close-search-icon {
  border: 1px solid #232323;
  height: 40px;
  background: #232323;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #626262;
  cursor: pointer;
  transition: color 0.5s ease-in-out;
}

.div-close-search-icon:hover {
  color: white;
}

.div-search-box {
  position: relative;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 0px 4px 0px #212121;
  border-radius: 5px;
}

.div-search-games-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.div-game-container {
  background-color: #202020;
  border-radius: 10px;
}

.div-search-game-poster img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}

.div-search-game-poster {
  position: relative;
}

.div-save-fun {
  position: absolute;
  /* bottom: 0px; */
  left: 0px;
  width: 100%;
  z-index: 1;
  background-image: linear-gradient(transparent, #202020 90%);
  display: flex;
  justify-content: center;
  align-items: self-end;
  gap: 10px;
  top: 0px;
  height: 100%;
}

.game-search-box {
  transition: all 0.5s ease-in-out;
}

.game-search-box:hover {
  box-shadow: 2px -1px 20px 0px black;
}

.div-text-info-container {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.div-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
}

.div-no-game {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #626262;
  width: 70%;
}

.div-search-games-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-menu-platforms-box {
  margin: 10px;
}

.platform-menu-links {
  margin-top: 10px;
  font-size: 18px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px;
}

.div-menu-user-info {
  margin: 10px;
}

.menu-user-info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.div-signin {
  display: flex;
  align-items: center;
  gap: 10px;
}

.div-signin svg {
  font-size: 20px;
}

@media (max-width: 800px) {
  .div-menu {
    display: flex;
  }

  .div-parent-link {
    display: none;
  }

  .div-press-key-search {
    display: none;
  }

  .input-disabled-search {
    width: 100px;
  }

  .div-game-logo {
    width: auto;
  }

  .div-no-game {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .input-disabled-search {
    width: 80px;
  }

  .div-search-box {
    width: 70%;
  }

  .div-no-game {
    width: 70%;
  }
}