.div-screenshot-parent {
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: 100%;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.div-screenshot-icon {
  border-radius: 100%;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
}