@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

.div-item-games {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 30px;
  text-align: center;
  width: 300px;
  border-radius: 10px;
  background-color: #202020;
  position: relative;
  transition: all ease-in-out 0.5s;
  top: 0px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 20px;
  margin-left: 20px;
  height: auto;
}

.div-item-games p {
  font-size: 25px;
  color: white;
}

.div-game-img {
  width: 100%;
  height: 180px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.div-item-games:hover {
  top: -20px;
}

.div-item-games:hover {
  position: relative;
  z-index: 2;
}

.div-progress-bar {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 20px; */
  margin: 30px;
  margin: 0px;
  margin-top: 70px;
  margin-bottom: 50px;
}

.div-loading-progress {
  width: 100px;
  display: flex;
  justify-content: center;
  /* background-color: #202020; */
  padding: 7px;
  border-radius: 10px;
}

img {
  width: 100%;
  height: 100%;
}

.div-parent-grid-title {
  position: relative;
  top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 50px;
  /* margin-top: 20px; */
  margin-right: 124px;
}


.div-grid-parent {
  /* border: 1px solid; */
  width: 100px;
  /* height: 30px; */
  display: flex;
}

.div-grid-1 {
  left: 0px;
  display: flex;
  border: 1px solid;
  width: 50%;
  height: 40px;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
}

.div-grid-2 {
  left: 0px;
  display: flex;
  border: 1px solid;
  width: 50%;
  height: 40px;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
}

.div-display-grid {
  display: flex;
  align-items: center;
}

.span-grid-text {
  margin-right: 10px;
}

.btn-click-change-grid-div-games-items {
  width: 60%;
}

/* media query for different devices screen resolution */
@media (max-width: 768px) {
  .div-item-games {
    padding-bottom: 5px;
    width: 80%;
    /* border: 1px solid; */
  }

  .div-display-grid {
    display: none;
  }

  .btn-click-change-grid-div-games-items {
    width: auto;
  }
}

@media (max-width: 426px) {
  .div-display-grid {
    display: none;
  }
}

.btn-click-change-grid-div-games-img {
  width: auto;
  height: auto !important;
}
