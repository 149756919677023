/* div after transition effect with motion.div */
.div-head {
  margin-right: 5px;
  display: grid;
  place-items: center;
  margin-top: 50px;
  margin-left: 100px;
}

/* typo__title class used inside every typography component */
.typo__title {
  color: gray;
  display: block;
  margin-bottom: 5px !important;
}

/* typography content text style */
.typo__content {
  font-size: 20px !important;
  padding: 5px;
}

/* genre insidde typography style */
.div__genre {
  background: #2d2c2c;
  color: #b1b1b1 !important;
  padding: 5px;
  font-size: 14px;
  margin: 5px;
  border-radius: 5px;
}

.div__genre a {
  color: #b1b1b1 !important;
}

/* progress bar */
.progress-bar {
  position: fixed !important;
  width: 100% !important;
  left: 0px !important;
  top: 0px !important;
  background-color: red !important;
}

/* skeleton loading */
.loading-skeleton {
  background: #292727 !important;
}

.text-skeleton {
  height: 30px !important;
}

/* next btn */
.next-img-slider-btn {
  position: absolute;
  top: 0px;
  height: 100%;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.next-img-slider-btn div {
  font-size: 40px;
  background: #161616;
  border-radius: 1000%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  transition: background-color 0.5s;
}

/* previous btn */
.prev-img-slider-btn {
  position: absolute;
  top: 0px;
  height: 100%;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.prev-img-slider-btn div {
  font-size: 40px;
  background: #161616;
  border-radius: 1000%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  transition: background-color 0.5s;
}

.prev-img-slider-btn div:hover,
.next-img-slider-btn div:hover {
  background-color: #303030;
}

/* game similer series */
.box-similer-games-img-container {
  position: relative;
}

.box-rating {
  position: absolute;
  height: -webkit-fill-available;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-image: linear-gradient(transparent, #202020 90%);
}

.similer-game-container {
  width: 100%;
  background-color: #202020;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.similer-game-container:hover {
  box-shadow: 1px 1px 15px 0px #000000;
}

.typo-game-series {
  font-size: 20px !important;
  margin-bottom: 20px;
}

.box-similer-game-details {
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.typo-rating {
  display: flex;
  align-items: center;
  gap: 10px;
}

.box-similer-game-series-parent {
  width: 90%;
}

.box-release-and-genre-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.box-release-and-genre-container p {
  font-size: 15px !important;
  flex-wrap: nowrap !important;
}

.box-release-and-genre-parent {
  margin-top: 10px;
}

/* box no similer game found */
.box-no-similer-game-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  color: #6c6c6c;
}

.div-game-detail-screenshot {
  width: 1000px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-platform {
  cursor: pointer;
}

.div-spec-parent-tab {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
}

.div-spec-tab {
  width: 50%;
  text-align: center;
  padding: 10px;
  transition: all 0.5s ease-in-out;
  user-select: none;
  cursor: pointer;
}

.div-spec-tab:hover {
  color: white;
}

.div-specification-dIscreption {
  padding: 10px;
  margin-bottom: 30px;
}

.div-dialog-close {
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 3px;
  transition: background-color 0.5s ease-in-out;
  background-color: transparent;
}

.div-dialog-close:hover {
  border-radius: 100%;
  background-color: #262626;
}

.div-dialog-title {
  padding: 20px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0px;
}

.div-no-spec {
  padding: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span-creaters {
  margin-left: 40px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

@media (max-width: 2000px) {
  .div-game-detail-screenshot {
    width: 800px;
  }
}

@media (max-width: 768px) {
  .div-head {
    margin-left: 10px;
  }

  .top-nav {
    padding-right: 0px;
  }

  .box-similer-game-series-parent {
    width: 95%;
  }
  .div-game-detail-screenshot {
    height: 350px;
    width: 100%;
  }
}

@media (max-width: 426px) {
  .img-poster {
    width: 90% !important;
  }

  .box-similer-game-series-parent {
    width: 100%;
  }
  .div-game-detail-screenshot {
    height: 250px;
    width: 100%;
  }
}
