body {
  background-color: #151515;
  color: white;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* scroll webkit style will be applied to every element overflow which has scroll */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(34, 33, 33);
}

.div-loading-progress {
  height: 90vh;;
}

@media (max-width: 768px) {
  .div--page {
    padding-left: 0px !important;
  }
}
